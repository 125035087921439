import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, List, Em, Section, Strong } from "@quarkly/widgets";
const defaultProps = {
	"background-color": "--dark",
	"text-align": "center",
	"padding": "32px 0",
	"quarkly-title": "Footer-1"
};
const overrides = {
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"font": "--lead"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"font": "--lead",
			"children": "Ana sayfa"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/about",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "ПРО НАС",
			"display": "none"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/services",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"font": "--lead",
			"children": "Menü"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "mailto:hello@company.com",
			"text-decoration-line": "none",
			"variant": "--base",
			"color": "--lightD1",
			"hover-color": "--primary",
			"margin": "2rem 0px 1rem 0px",
			"font": "--lead",
			"children": <>
				Coffee Haven'ı Bugün Ziyaret Edin
				<br />
				<br />
				{" "}Coffee Haven'ın neden dinlenmek ve harika bir fincan kahvenin tadını çıkarmak için mükemmel bir yer olduğunu keşfedin. Sizi ağırlamayı ve gününüzü biraz daha parlak hale getirmeyi sabırsızlıkla bekliyoruz. Gelin ve farkı yaşayın.
				<br />
				<br />
				<br />
				<Strong>
					<Em>
						Firuzağa, Hayriye Cd. 18/A, 34425 Beyoğlu/İstanbul, Türkiye
						<br />
						+902122432262
					</Em>
				</Strong>
			</>
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "mailto:hello@company.com",
			"text-decoration-line": "none",
			"variant": "--base",
			"color": "--lightD1",
			"hover-color": "--primary",
			"margin": "2rem 0px 0px 0px",
			"children": <Em>
				Copyright 2024.
			</Em>
		}
	}
};

const Footer12 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</List>
		<Link {...override("link3")} />
		<Link {...override("link4")} />
		{children}
	</Section>;
};

Object.assign(Footer12, { ...Section,
	defaultProps,
	overrides
});
export default Footer12;